import { LogOut, reset } from "../features/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SidebarFree = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logout = () => {
        dispatch(LogOut());
        dispatch(reset());
        navigate("/");
    };

    var { user } = useSelector(
      (state) => state.auth
    );
    
    return (
        
        <div id="sidebar" className="active">
            <div className="sidebar-wrapper active">
                <div className="sidebar-header position-relative">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo">
                            <h5><small>{ user ? user.toko_name : ""}</small></h5>
                        </div>
                        <div className="theme-toggle d-flex gap-2 align-items-center mt-2">
                            <div className="form-check form-switch fs-6">
                            <input
                                className="form-check-input me-0"
                                type="checkbox"
                                id="toggle-dark"
                                style={{cursor: "pointer"}}
                            />
                            <label className="form-check-label"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-0"/>
                {/* <Link to="/user_setting">
                    <div className="row">
                        <div className="col-3">
                            <div className="avatar bg-warning ms-3">
                                <img src="./assets/images/faces/1.jpg" alt="" />
                                <span className="avatar-status bg-success"></span>
                            </div>
                        </div>
                        <div className="col-9 mt-1">
                            <h5 className="text-gray-600"><small>{ user ? user.name : ""}</small></h5>
                        </div>
                    </div>
                </Link>
                <hr /> */}
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-title"><strong>Free Menu</strong></li>
                        <Link to="/dashboard">
                            <li className="sidebar-item active">
                                <div className="sidebar-link">
                                <i className="bi bi-grid-fill"></i>
                                <span>Dashboard</span>
                                </div>
                            </li>
                        </Link>
                        <Link to="/inventory">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-box-seam"></i>
                                <span>Inventory</span>
                                </div>
                            </li>
                        </Link>
                        <Link to="/cash_flow">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-cash-stack"></i>
                                <span>Cash Flow</span>
                                </div>
                            </li>
                        </Link>
                        <Link to="/about">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-exclamation-circle-fill"></i>
                                <span>About</span>
                                </div>
                            </li>
                        </Link>
                        <li className="sidebar-item" onClick={logout} style={{cursor: "pointer"}}>
                            <div className="sidebar-link">
                            <i className="bi bi-box-arrow-left"></i>
                            <span>Logout</span>
                            </div>
                        </li>

                        <li className="sidebar-title mt-4 mt-md-5"><strong>Gold Menu</strong></li>
                        <Link to="/upgrade">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-cash-register"></i>
                                <span>Kasir</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-chart-line"></i>
                                <span>Analisa</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-file-earmark-text"></i>
                                <span>Invoice</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-users"></i>
                                <span>Sub Akun ( 3 )</span>
                                </div>
                            </li>
                        </Link>

                        <li className="sidebar-title mt-4 mt-md-5"><strong>Premium Menu</strong></li>
                        <Link to="/upgrade">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-qr-code-scan"></i>
                                <span>Kasir Dengan Scanner</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-link"></i>
                                <span>Domain Pribadi</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-users"></i>
                                <span>Sub Akun ( 10 )</span>
                                </div>
                            </li>
                        </Link>
                        
                        <li className="sidebar-title mt-4 mt-md-5"><strong>Support Developer</strong></li>
                        <Link to="/upgrade_toko">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-arrow-up-circle-fill"></i>
                                <span>Upgrade Toko</span>
                                </div>
                            </li>
                        </Link>
                        <Link to="/donasi">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-wallet"></i>
                                <span>Donasi</span>
                                </div>
                            </li>
                        </Link>
                        <Link to="/bug_report">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-bug"></i>
                                <span>Bug Report</span>
                                </div>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SidebarFree;

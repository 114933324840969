const API_USERS = process.env.REACT_APP_API_URL + 'users'
const API_LOGIN = process.env.REACT_APP_API_URL + 'login'
const API_LOGOUT = process.env.REACT_APP_API_URL + 'logout'
const API_ME = process.env.REACT_APP_API_URL + 'me'


export {
    API_USERS,
    API_LOGIN,
    API_LOGOUT,
    API_ME
}
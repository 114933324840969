import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    
    /* -================= LOAD EXTERNAL SCRIPT ==================- */
    const [showDelay, setShowDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setShowDelay(false)
        }, 100)

        if(showDelay === false){
        const script = document.createElement("script");
        script.src = "assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        }
        return () => clearTimeout(timeout)
    }, [showDelay]);
    /* -================ END OF EXTERNAL SCRIPT =================- */
    return (
        <>
            <div id="main">
                <header className="mb-3">
                    <div className="burger-btn d-block d-xl-none">
                        <i className="bi bi-justify fs-3"></i>
                    </div>
                </header>

                <div className="page-heading">
                    <h3>404</h3>
                </div>

                <section id="content-types">
                    <div className="row mt-5">
                        <div className="col-lg-4 col-sm-12">
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="text-center">
                                <img
                                    className="img-error"
                                    src="assets/images/samples/error-404.svg"
                                    alt="Not Found"
                                />
                                <h1 className="error-title">NOT FOUND</h1>
                                <p className="fs-5 text-gray-600">The page you are looking not found.</p>
                                <Link to='/dashboard' className="btn btn-lg btn-outline-primary mt-3">Go Home</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default NotFound
import { useSelector } from "react-redux";
import SidebarFree from "./SidebarFree";
import SidebarNA from "./SidebarNA";

const Sidebar = () => {

    var { user } = useSelector(
      (state) => state.auth
    );

    return (
        <>  
            {user ? 
                <>
                    {user.toko_type === "Free"? 
                        <SidebarFree />
                    : "" }
                    {user.toko_type === "Gold"? 
                        <SidebarFree />
                    : "" }
                    {user.toko_type === "Premium"? 
                        <SidebarFree />
                    : "" }
                </>
            : <SidebarNA />}
        </>
    );
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getMe } from "../features/authSlice";

const Dashboard = () => {
    /* -================= LOAD EXTERNAL SCRIPT ==================- */
    const [showDelay, setShowDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setShowDelay(false)
        }, 500)

        if(showDelay === false){
        const script = document.createElement("script");
        script.src = "assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        }
        return () => clearTimeout(timeout)
    }, [showDelay]);
    /* -================ END OF EXTERNAL SCRIPT =================- */

    /* -===================== AUTHORIZATION =====================- */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError, user } = useSelector((state) => state.auth);
    
    //auth process
    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);
    
    useEffect(() => {
        if (isError) {
            new Swal({
                title: "Unauthorized !",
                text: "Mohon login ke akun anda",
                icon: "error",
                timer: 2000,
                showConfirmButton: false,
            });
            navigate("/");
        }
        if (user && user.role !== "admin") {
            new Swal({
                title: "Unauthorized !",
                text: "Anda bukan admin",
                icon: "error",
                timer: 2000,
                showConfirmButton: false,
            });
            navigate("/");
        }
    }, [isError, user, navigate]);
    /* -================== END OF AUTHORIZATION ================== */
 
    return (
        <>
            {user ?
                <div id="main">
                    <header className="mb-3">
                        <div className="burger-btn d-block d-xl-none">
                            <i className="bi bi-justify fs-3"></i>
                        </div>
                    </header>

                    <div className="page-heading">
                        <h3>Dashboard</h3>
                    </div>

                    <section id="content-types">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <div className="card mx-2">
                                    <div className="card-header">
                                        <h5 className="text-center">Informasi Toko</h5>
                                    </div>
                                    <div className="card-body">
                                        <dl className='row'>
                                            <dt className='col-3 mb-2'>
                                                Toko
                                            </dt>
                                            <dt className='col-9'>
                                                : {user.toko_name}
                                            </dt>
                                            <dt className='col-3 mb-2'>
                                                Toko ID 
                                            </dt>
                                            <dt className='col-9'>
                                                : {user.toko_id}
                                            </dt>
                                            <dt className='col-3 mb-2'>
                                                Type 
                                            </dt>
                                            <dt className='col-9'>
                                                : {user.toko_type}
                                            </dt>
                                            <dt className='col-3 mb-2'>
                                                Owner
                                            </dt>
                                            <dt className='col-9'>
                                                : {user.name}
                                            </dt>
                                        </dl>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <button className="btn btn-warning btn-block">
                                            <i className="bi bi-arrow-up-circle-fill"></i> &nbsp; Upgrade Toko
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </section>
                </div>
            : "loading..."}
        </>
    );
};

export default Dashboard

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const SidebarNA = () => {

    var { user } = useSelector(
      (state) => state.auth
    );
    
    if(user === null){
        user = {
            name: "User Belum Login",
            toko_name: "Manager Toko"
        }
    }

    const unauth = () => {
        new Swal({
            title: "Unauthorized !",
            text: "Mohon login ke akun anda",
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
        });
    }

    return (
        
        <div id="sidebar" className="active">
            <div className="sidebar-wrapper active">
                <div className="sidebar-header position-relative p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>Managet Toko - OT</h5>
                        <div className="theme-toggle d-flex gap-2 align-items-center mt-0">
                            <div className="form-check form-switch fs-6">
                            <input
                                className="form-check-input me-0"
                                type="checkbox"
                                id="toggle-dark"
                                style={{cursor: "pointer"}}
                            />
                            <label className="form-check-label"></label>
                            </div>
                        </div>
                        {/* <div className="sidebar-toggler x">
                            <div className="sidebar-hide d-xl-none d-block">
                                <i className="bi bi-x bi-middle"></i>
                            </div>
                        </div> */}
                    </div>
                </div>
                <hr className="mt-0"/>
                <div className="row">
                    <div className="col-3">
                        <div className="avatar bg-warning ms-3">
                            <img src="./assets/images/faces/1.jpg" alt="" />
                            <span className="avatar-status bg-success"></span>
                        </div>
                    </div>
                    <div className="col-9 mt-1">
                        <h5 className="text-gray-600"><small>{ user ? user.name : ""}</small></h5>
                    </div>
                </div>
                <hr />
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-title"><strong>Free Menu</strong></li>
                        <Link to="/">
                            <li className="sidebar-item" style={{cursor: "pointer"}}>
                                <div className="sidebar-link">
                                <i className="bi bi-box-arrow-in-right"></i>
                                <span>Login</span>
                                </div>
                            </li>
                        </Link>
                        <div onClick={unauth}>
                            <li className="sidebar-item" style={{cursor: "pointer"}}>
                                <div className="sidebar-link">
                                <i className="bi bi-grid-fill"></i>
                                <span>Dashboard</span>
                                </div>
                            </li>
                            <li className="sidebar-item" style={{cursor: "pointer"}}>
                                <div className="sidebar-link">
                                <i className="bi bi-box-seam"></i>
                                <span>Inventory</span>
                                </div>
                            </li>
                            <li className="sidebar-item" style={{cursor: "pointer"}}>
                                <div className="sidebar-link">
                                <i className="bi bi-cash-stack"></i>
                                <span>Cash Flow</span>
                                </div>
                            </li>
                        </div>
                        <Link to="/about">
                            <li className="sidebar-item" style={{cursor: "pointer"}}>
                                <div className="sidebar-link">
                                <i className="bi bi-exclamation-circle-fill"></i>
                                <span>About</span>
                                </div>
                            </li>
                        </Link>

                        <li className="sidebar-title mt-4 mt-md-5"><strong>Gold Menu</strong></li>
                        <div onClick={unauth}>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-cash-register"></i>
                                <span>Kasir</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-chart-line"></i>
                                <span>Analisa</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-file-earmark-text"></i>
                                <span>Invoice</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-users"></i>
                                <span>Sub Akun ( 3 )</span>
                                </div>
                            </li>
                        </div>

                        <li className="sidebar-title mt-4 mt-md-5"><strong>Premium Menu</strong></li>
                        <div onClick={unauth}>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-qr-code-scan"></i>
                                <span>Kasir Dengan Scanner</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-link"></i>
                                <span>Domain Pribadi</span>
                                </div>
                            </li>
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="fas fa-users"></i>
                                <span>Sub Akun ( 10 )</span>
                                </div>
                            </li>
                        </div>
                        
                        <li className="sidebar-title mt-4 mt-md-5"><strong>Support Developer</strong></li>
                        <Link to="/donasi">
                            <li className="sidebar-item">
                                <div className="sidebar-link">
                                <i className="bi bi-wallet"></i>
                                <span>Donasi</span>
                                </div>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SidebarNA;

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";

function Login() {
    /* -================= LOAD EXTERNAL SCRIPT ==================- */
    const [showDelay, setShowDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setShowDelay(false)
        }, 100)

        if(showDelay === false){
        const script = document.createElement("script");
        script.src = "assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        }
        return () => clearTimeout(timeout)
    }, [showDelay]);
    /* -================ END OF EXTERNAL SCRIPT =================- */

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isError, isSuccess, isLoading, message } = useSelector(
      (state) => state.auth
    );
  
    useEffect(() => {
      if (user || isSuccess) {
        navigate("/dashboard");
      }
      dispatch(reset());
    }, [user, isSuccess, dispatch, navigate]);
  
    const Auth = (e) => {
      e.preventDefault();
      dispatch(LoginUser({ email, password }));
      console.log(message)
    };

    return (
        <>
            <div id="main">
                <header className="mb-3">
                    <div className="burger-btn d-block d-xl-none">
                        <i className="bi bi-justify fs-3"></i>
                    </div>
                </header>

                <div className="page-heading">
                    <h3>Authentication</h3>
                </div>

                <section id="content-types">
                    <div className="row mt-md-5">
                        <div className="col-lg-4 col-sm-12">
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="card mx-2">
                                <form onSubmit={Auth}>
                                    <div className="card-body">
                                        <h5 className="mb-5 text-center">Login Manager Toko</h5>
                                        {isError && <p className="alert alert-danger text-center">{message}</p>}
                                        <div className='form-group'>
                                            <div className="row mb-4">
                                                <div className="col-md-4 col-sm-12">
                                                    <label>e - Mail</label>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <input
                                                        className="form-control" 
                                                        type="text" 
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder="e - mail" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-4 col-sm-12">
                                                    <label>Password</label>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <input
                                                        className="form-control" 
                                                        type="password" 
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        placeholder="****" 
                                                        autoComplete="off"
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row text-center'>
                                            <p>
                                                Belum punya akun? <Link to='/register'>&nbsp;Daftar Gratis</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between" type="submit">
                                        <button className="btn btn-light-primary btn-block">
                                            {isLoading ? "Loading..." : "Login"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>      
                </section>
            </div>
        </>
    )
}

export default Login
import { useEffect, useState } from "react";

const About = () => {
    /* -================= LOAD EXTERNAL SCRIPT ==================- */
    const [showDelay, setShowDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setShowDelay(false)
        }, 200)

        if(showDelay === false){
        const script = document.createElement("script");
        script.src = "assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        }
        return () => clearTimeout(timeout)
    }, [showDelay]);
    /* -================ END OF EXTERNAL SCRIPT =================- */
    return (
        <>  
            <div id="main">
                <header className="mb-3">
                    <nav class="navbar navbar-default navbar-top">
                        <div className="burger-btn d-block d-xl-none">
                            <i className="bi bi-justify fs-3"></i>
                        </div>
                        <ul className="navbar-nav ms-auto mb-lg-0">
                        </ul>
                        <div className="user-menu d-flex">
                            <div className="user-name text-end me-3">
                                <h6 className="mb-0 text-gray-600">John Ducky</h6>
                                <p className="mb-0 text-sm text-gray-600">Administrator</p>
                            </div>
                            <div className="user-img d-flex align-items-center">
                                <div className="avatar avatar-md">
                                    <img src="assets/images/faces/1.jpg" alt="aa"/>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="page-heading">
                    <h3>About</h3>
                </div>

                <section id="content-types">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="card mx-2">
                                <div className="card-header">
                                    <h5 className="text-center">Informasi Aplikasi</h5>
                                </div>
                                <div className="card-body">
                                    <dl className='row'>
                                        <dt className='col-4 mb-2'>
                                            Nama
                                        </dt>
                                        <dt className='col-8'>
                                            : Manager Toko
                                        </dt>
                                        <dt className='col-4 mb-2'>
                                            Versi
                                        </dt>
                                        <dt className='col-8'>
                                            : v1.0
                                        </dt>
                                        <dt className='col-4 mb-2'>
                                            Url 
                                        </dt>
                                        <dt className='col-8'>
                                            : <a href="https://managertoko.com">managertoko.com</a>
                                        </dt>
                                        <dt className='col-4 mb-2'>
                                            Developer
                                        </dt>
                                        <dt className='col-8'>
                                            : Otodidak Technology
                                        </dt>
                                    </dl>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <button className="btn btn-primary">
                                        <i className="bi bi-bug"></i> &nbsp; Bug Report
                                    </button>
                                    <button className="btn btn-warning">
                                        <i className="bi bi-wallet"></i> &nbsp; Donate
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="card mx-2">
                                <div className="card-header">
                                    <h5 className="text-center">Informasi Developer</h5>
                                </div>
                                <div className="card-body">
                                    <dl className='row'>
                                        <dt className='col-4 mb-2'>
                                            Instansi
                                        </dt>
                                        <dt className='col-8'>
                                            : Otodidak Technology
                                        </dt>
                                        <dt className='col-4 mb-2'>
                                            Bidang
                                        </dt>
                                        <dt className='col-8'>
                                            : Web, Web App & IoT
                                        </dt>
                                        <dt className='col-4 mb-2'>
                                            Berdiri
                                        </dt>
                                        <dt className='col-8'>
                                            : 2022
                                        </dt>
                                        <dt className='col-4 mb-2'>
                                            Domisili 
                                        </dt>
                                        <dt className='col-8'>
                                            : Bekasi, Jawa Barat
                                        </dt>
                                    </dl>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <button className="btn btn-warning btn-block">
                                        <i className="fas fa-address-card"></i> &nbsp; Contact Us
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="card mx-2">
                                <div className="card-body">
                                    <div className="text-center mt-4 mb-3">
                                        <img src="./assets/images/faces/fiqri.jpg" alt="" style={{borderRadius: "50%", width: "171px", heigth: "171px"}}/>
                                    </div>
                                </div>
                                <div className="card-footer text-center p-2">
                                    <h5>Muhammad Fiqri Aulia</h5>
                                    <small>Founder Otodidak Technology</small>
                                </div>
                            </div>
                        </div>
                    </div>      
                </section>
            </div>
        </>
    );
};

export default About
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './component/Footer';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import About from './pages/About';
import Sidebar from './component/Sidebar';
import NotFound from './component/NotFound';
import Inventory from './pages/Inventory';

function App() {

  return (
    <div id="app">
      <BrowserRouter>
        <Sidebar/>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about" element={<About />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/inventory" element={<Inventory />} />
          </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react'

function Footer() {
    return (
        <footer className="mx-3">
            <div className="footer clearfix mb-0 text-muted">
                <div className="float-end">
                    <p className='mb-0'>
                        Manager Toko v1.0 by <a href='https://otodidak.tech'>otodidak.tech</a>
                    </p>
                </div>
            </div><div className="footer clearfix mb-0 text-muted">
                <div className="float-end">
                    <p className='mb-0'>
                        theme by <a href='https://github.com/zuramai/mazer/'>zuramai</a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_USERS } from '../app/API';

function Register() {
    /* -================= LOAD EXTERNAL SCRIPT ==================- */
    const [showDelay, setShowDelay] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
        setShowDelay(false)
        }, 200)

        if(showDelay === false){
        const script = document.createElement("script");
        script.src = "assets/js/app.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
        }
        return () => clearTimeout(timeout)
    }, [showDelay]);
    /* -================ END OF EXTERNAL SCRIPT =================- */

    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [name, setName] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [email, setEmail] = useState('');
    const [TokoName, setTokoName] = useState('');

    const navigate = useNavigate();
    const createUser = async (e) => {
        e.preventDefault(e);
        if(isEmailValid){
            await axios.post(API_USERS, {
                name: name,
                password: password,
                confPassword: confPassword,
                email: email,
                role: "admin",
                toko_id: email,
                toko_name: TokoName
            })
            .then(res => {
                if(res.data.message === "success create user"){
                    new Swal({
                        title: "Success !",
                        text: "Berhasil Menambahkan User",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    navigate("/");
                }
            })
            .catch(err =>{
                if(err.response.data.message){
                    setErrorMsg(<div className='alert alert-danger text-center'>{err.response.data.message}</div>)
                }else{
                    setErrorMsg(<div className='alert alert-danger text-center'>Internal Error</div>)
                }
            })
        }else{
            setErrorMsg(<div className='alert alert-danger text-center'>Periksa Kembali Email Anda</div>)
        }
    }

    const handleEmail=(e)=>{
        const value = e.target.value
        setEmail(value)
        
        const validateEmail = (email) => {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
            return re.test(String(email).toLowerCase());
        };
        setIsEmailValid(validateEmail(email));
    }

    return (
        <>
            <div id="main">
                <header className="mb-3">
                    <div className="burger-btn d-block d-xl-none">
                        <i className="bi bi-justify fs-3"></i>
                    </div>
                </header>

                <div className="page-heading">
                    <h3>Registration</h3>
                </div>

                <section id="content-types">
                    <div className="row mt-md-5">
                        <div className="col-lg-4 col-sm-12">
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="card mx-2">
                                <form onSubmit={createUser}>
                                    <div className="card-body">
                                        <h5 className="mb-5 text-center">Daftar Manager Toko</h5>
                                        
                                        <div className='form-group'>
                                            <div className="row mb-4">
                                                {errorMsg}
                                                <div className="col-md-4 col-sm-12">
                                                    <label>Nama Pemilik</label>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <input
                                                        className="form-control" 
                                                        type="text" 
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        placeholder="Nama Pemilik" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-4 col-sm-12">
                                                    <label>e - Mail</label>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <input
                                                        className="form-control" 
                                                        type="text" 
                                                        value={email}
                                                        onChange={(e) => {handleEmail(e)}}
                                                        placeholder="e - mail" 
                                                        required 
                                                    />
                                                    {!isEmailValid && <p className="text-danger">*Isi dengan email yang valid</p>}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-4 col-sm-12">
                                                    <label>Password</label>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <input
                                                        className="form-control" 
                                                        type="password" 
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        placeholder="****" 
                                                        autoComplete="off"
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-4 col-sm-12">
                                                    <label>Konfirmasi Password</label>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <input
                                                        className="form-control" 
                                                        type="password" 
                                                        value={confPassword}
                                                        onChange={(e) => setConfPassword(e.target.value)}
                                                        placeholder="****" 
                                                        autoComplete="off"
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-4 col-sm-12">
                                                    <label>Nama Toko</label>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <input
                                                        className="form-control" 
                                                        type="text" 
                                                        value={TokoName}
                                                        onChange={(e) => setTokoName(e.target.value)}
                                                        placeholder="Nama Toko" 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row text-center'>
                                            <p>
                                                Sudah punya akun? <Link to='/'>&nbsp;Login</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-between">
                                        <button className="btn btn-light-primary btn-block" type="submit">
                                            Daftar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>      
                </section>
            </div>
        </>
    )
}

export default Register